import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form, Input, Button, Card , message, Spin } from "antd"
import SuccessLogo from "../oonaImages/GRFX Success State.png"
import { useHistory } from "react-router-dom"
//import InputImage from "../../oonaImages/Frame 1000004254.png"
import InputImage from '../oonaImages/Frame 1000004254.png'
import axios from "axios"
//import rootIndex from "../../store/root_index";
import rootIndex from '../store/root_index'
import { Base_URL } from '../config/BaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import axiosRequest from './../axios-request/request.methods'

import { useLanguage } from "../../src/config/LanguageContext"
import { useSelector,useDispatch } from 'react-redux'
import CustomerHeader from '../CustomerHeader/CustomerHeader'


const { store } = rootIndex;

const HomesuccessPrintPolicy = (props) => {


    const { translate } = useLanguage()
    const fetchingIDfromStore=useSelector((state)=>state?.cipherlinkreducer?.quote?.quoteId)
    console.log(fetchingIDfromStore,"fetchingIDfromStore")
    const checkingResumeType=useSelector((state)=>state?.cipherlinkreducer?.quote?.resumeType);
    const checkingType=useSelector((state)=>state?.cipherlinkreducer?.quote);
    const applyingpoposalNumber=useSelector((state)=>state?.cipherlinkreducer?.quote?.ProposerNumberdata);
    console.log(applyingpoposalNumber,"applyingpoposalNumber")
    const fetchingWholeDatafromStore=useSelector((state)=>state?.cipherlinkreducer?.quote)
    console.log(checkingType,"checkingType")
    const location = useLocation()
    const data = useSelector((state) => state.paQuotefetchedData);
    const { paQuotefetchedData } = data;
 
    console.log(location, "location===>")
    console.log(props, "propsss")
    const _store = store.getState();

    const history = useHistory()

    const [copyLink, setCopyLInk] = useState("")

    const [quoteData, setQuoteData] = useState("")
    const language = useSelector((state) => state.languageReducer.lang);
    const [paylaterData, setPayLaterData] = useState([])
    console.log(quoteData,"quoteDatageetha")

    useEffect(() => {
        getQuotes()
      }, [])
      const isoTimestamp = paylaterData?.errMsg?.data?.payLaterDueDate;
      const dateObject = new Date(isoTimestamp);
      const simpleFormattedDate = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;

      const getQuotes = () => {
        axiosRequest
          .get(
            `user/get-pa-quote/${fetchingIDfromStore}`,
          )
          .then((data) => {
            if (data?.errCode === -1) {
              setPayLaterData(data)
              console.log(simpleFormattedDate,"jeni")
            }
          }).catch((error) => {
          });
      }
    useEffect(() => {

        var fromReferral = ""
        if(checkingResumeType === "referalJourney" ||  checkingResumeType === "quoteSearch"){
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}&logkey=${checkingResumeType}`
        }
        else{
          fromReferral=`user/get-quotes?id=${fetchingIDfromStore}`
        }

        axiosRequest
            .get(
                `${fromReferral}`,
                
            )
            .then((data) => {
                if(data?.errCode === -1){}
                    setQuoteData(data.errMsg.data[0])
               
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            })
        let endPoint = checkingResumeType === 'motor' ? 'generate-link-motor' : 'generate-link'
        axiosRequest
            .get(
                `user/${endPoint}?type=lead&quoteId=${fetchingIDfromStore}&isEPdfLink=true&pdfType=policy`,
                
            )
            .then((data) => {
                if(data?.errCode === -1){
                console.log("forgot passs  : ", data.errMsg);
                    setCopyLInk(data?.errMsg?.link)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });

    }, [fetchingIDfromStore])

    const policyPdf = () => {

      
        let  options = { secure: true, multipart: true }


        checkingResumeType === 'motor'


            ? (axiosRequest
                .get(
                    `user/e-policy?policyNo=${fetchingWholeDatafromStore?.qNo}&quoteId=${fetchingIDfromStore}`,
                    options
                )
                .then((response) => { 
                    console.log(response,"bcshjfgy")
                    
                        const blob = response ;
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        console.log(fileURL,"fileURLfileURL==>")
                        // Setting various property values
                        const alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = `PS_MOTOR Insurance_${fetchingWholeDatafromStore?.qNo}.pdf`;
                        alink.click();
    
                        // Clean up the object URL after the download is triggered
                        window.URL.revokeObjectURL(fileURL);
                    
                })
                .catch((error) => {
                    console.log("Error: ", error);
                })
                
                )


            : (axiosRequest
                .get(
                    `user/home-ePolicy/${fetchingIDfromStore}?quoteId=${fetchingIDfromStore}`,
                    options
                )
                .then((response) => { 
                    console.log(response,"bcshjfgy")
                   
                        const blob = response ;
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        console.log(fileURL,"fileURLfileURL==>")
                        // Setting various property values
                        const alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = `PS_Home Insurance_${applyingpoposalNumber?.SessionId}.pdf`;
                        alink.click();
    
                        // Clean up the object URL after the download is triggered
                        window.URL.revokeObjectURL(fileURL);
                    
                })
                .catch((error) => {
                    console.log("Error: ", error);
                })
                
                )
    }

    const handleCopyLInk = () => {
        console.log(copyLink,"copyLink")
        navigator.clipboard.writeText(copyLink)
        toast.success(`${translate("home")["link copied"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
    }

    const [form] = Form.useForm();


    useEffect(() => {
        form.setFieldsValue({
            copyLink: copyLink
        })

        setCopyLInk(copyLink)
    }, [copyLink])

    return (
        <>
        <CustomerHeader/>
        <div>
            {/* <Spin tip="Loading" > */}
            <Row className='success-content'>
                <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                    <Image preview={false} src={SuccessLogo} />
                </Col>
                <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                    <h5 className='header-card-content'>{translate('travel')["Success!"]}!</h5>
                    <div><b>{translate('home')["E-policy has been sent to you via email"]}</b></div>
                    <div style={{ fontSize: 12, color: "lightgray" }}>{translate('travel')["Reference no"]} : {applyingpoposalNumber?.SessionId}</div>
                    <br />
                 
                        <>
                            <div><b>{translate('home')["Send this e-policy to the client"]}</b></div>
                            <Form form={form}>
                                <Form.Item
                                    name="copyLink"
                                >
                                    <Input value={copyLink} suffix={<Image className="input-img" src={InputImage} preview={false} onClick={() => handleCopyLInk()} />}  className='link-input' />

                                    {/* <Input suffix={<><Image className="input-img" src={InputImage} preview={false} /></>} defaultValue="https://oo.na/agent/83dcg" className='link-input'></Input> */}
                                </Form.Item>
                            </Form>
                            { quoteData?.paymentStatus == "PayLater" &&
                             
                            ( <Col xl={24} md={24} lg={24} xs={24} sm={24}>
                                    <Card className='card-shadow' style={{ marginBottom: 5, borderRadius: 5 }}>
                                    <div className='info-font' style={{ fontWeight: 600, fontSize: 15 }}>{translate('home')["completePayment"]}</div>
                                    <h5 className='header-card-content'>{simpleFormattedDate}</h5>
                                    </Card>
                                </Col>)
                            }
                        </>
                    
                    <Row>
                        <Col md={12} xl={12} sm={12} xs={12} lg={12}>
                            <Button onClick={() => history.push('/oonahome')} className='no-button'>{translate("motor")["Back to Dashboard"]}</Button>
                        </Col>
                        <Col md={12} xl={12} sm={12} xs={12} lg={12}>
                            <Button className='next-button-travel' onClick={() => policyPdf()}>{translate("home")["Print Policy"]}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* </Spin> */}
        </div>
        </>
    )
}

export default HomesuccessPrintPolicy
