import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form, Input, Button, message, Spin } from "antd"
import SuccessLogo from "../oonaImages/GRFX Success State.png"
import uploadLogo from '../oonaImages/GRFX Upload.png'
import { useHistory } from "react-router-dom"
//import InputImage from "../../oonaImages/Frame 1000004254.png"
import InputImage from '../oonaImages/Frame 1000004254.png'
//import rootIndex from "../../store/root_index";
import rootIndex from '../store/root_index'
import { Base_URL } from '../config/BaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import './oona-aob.css'
import { useLanguage } from '../config/LanguageContext'
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Header } from "antd/lib/layout/layout"
import oonaLogo from "../images/oona-logo@2x.png"
import LangSwitch from '../config/LangSwitch'
import axiosRequest from './../axios-request/request.methods'
import CustomerHeader from '../CustomerHeader/CustomerHeader'

const AOBsuucessScreenOCR = (props) => {
console.log(props,"props")
  const { translate }=useLanguage()

  const history = useHistory()
  const [idURldata,setIdData]=useState({})
  const [loading,setLoading]=useState(false)
  console.log(idURldata,"idURldata")

  useEffect(()=>{
    if(props?.location?.state?.details?.idNumber !== "" || props?.location?.state?.details?.idNumber !== undefined){
      setTimeout(fetchingOCRDetails, 5000);
    }
   
  },[])


  useEffect(()=>{
    if(idURldata.hasOwnProperty('idNumber') || idURldata.hasOwnProperty('idType')){
      setLoading(false)
    }
    else{
      setLoading(true)
    }
  
  },[idURldata])
  
  
  const fetchingOCRDetails=()=>{
   
  
    let axiosConfig = {
      headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + props?.location?.state?.cipher
      },
    };
    let options = { secure: false, multipart: false }

    axiosRequest
    .get(
        `auth/hyperverge/ocr-details?transactionId=${props?.location?.state?.KYCResult?.transactionId}`,
        options
    )
    .then((data) => {
        if(data?.errCode === -1){
            //console.log(data?.data?.errMsg,"yuteyfuv") 
            setIdData(data?.errMsg?.details?.ocrDetails)
          }
    }).catch((error) => {
      // toast.error("Verification service is not responding as expected. Please try after sometime")
        console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
        // setLoading(true)
    });
  
  }


  const handleupdateprofile=()=>{
    setLoading(true)
    
let payloadforIdCard={
idNumber:props?.location?.state?.KYCResult?.details?.idNumber,
idUrl:idURldata?.s3Url,
idType:idURldata?.idType

}

console.log(payloadforIdCard,"payloadforIdCard")
    axiosRequest
    .patch(
      `agent/update-profile`,
      payloadforIdCard,
    
    ).then((data) => {
      //console.log("forgot passs  : ", data.data.errMsg);
      // console.log("Final response: of new lead", data.errMsg.result_valid_mobile[0]);
      if(data?.errCode === -1){
        setLoading(false)
        history.push({
          pathname: '/AOBIndividualIdCardTermsandConditions',
          state: {
            data: props?.location?.state?.data,
            
            phonenumber:props?.location?.state?.phonenumber,
            userId:props?.location?.state?.userId,
            token:props?.location?.state?.token,
            termsandconditionsData:{
              individualCreatePassword:props?.location?.state?.termsandconditionsData?.individualCreatePassword,
              individualEmailaddress:props?.location?.state?.termsandconditionsData?.individualEmailaddress,
              individualFullName:props?.location?.state?.termsandconditionsData?.individualFullName
            }
          },
        })
      }
      if(data?.errCode === -1){
        setLoading(false)
        if(data?.errMsg?.message === "Duplicate ID Number."){
          toast.error(data?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
          history.push({
            pathname: '/AOBmitraRegistrationForm',
            // state: {
            //   data: "declinedeoorCorRetake",
            //   phonenumber: props?.location?.state?.phonenumber,
            //   userId: props?.location?.state?.userId,
            //   activekey:"cor"
            // },
            state: {
              data: "retakePhotoData",
              phonenumber:props?.location?.state?.phonenumber,
              userId:props?.location?.state?.userId,
              activekey:"ind",
              corPoratetaxpayer : true,

            },
          })
        }
      }

    }).catch((error) => {
      console.log("first",error)
      console.log("second",error.response)
      console.log("Third",error?.response?.data ,error?.response?.data.errMsg,error?.response?.data?.errCode)
      setLoading(false)
     
        //history.push('/AOBmitraRegistrationForm')
        toast.error(error?.response?.data?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
        if(error?.response?.data?.errMsg?.message === "Duplicate ID Number."){
          history.push({
            pathname: '/AOBmitraRegistrationForm',
            // state: {
            //   data: "declinedeoorCorRetake",
            //   phonenumber: props?.location?.state?.phonenumber,
            //   userId: props?.location?.state?.userId,
            //   activekey:"cor"
            // },
            state: {
              data: "retakePhotoData",
              phonenumber:props?.location?.state?.phonenumber,
              userId:props?.location?.state?.userId,
              activekey:"ind",
              corPoratetaxpayer : true,

            },
          })
        }
      
      console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);
    });

}
const location=useLocation()
  return (
    <>
     
        {/* {location?.state.cipher != undefined?
          <div className="header__padding">
          <Header className="header-login">
          <div className="oona__customer__flow  newWithOUtLInk">
        <Image preview={false} className="oona-logo" src={oonaLogo}></Image>
        <LangSwitch/>
        </div>

      </Header> </div>: null} */}

      <CustomerHeader/>
    <div className="row__aob oona_aob_font">
    <Spin tip="Loading" spinning={loading} >
      <Row>
        <Col xl={12} md={12} lg={12}>
          <Image preview={false} src={uploadLogo} />
        </Col>
        <Col xl={12} md={12} lg={12}>
          <div className="oonaSuccess__ocrkahoona">
            <div className="oonaSuccess__ocrkahoona__header">
              {translate("onboarding")["We’ve Got Your ID Card Data"]}</div>
            <div className="oonaSuccess__kahoona__header__cenetered oona_aob_font">
              <div className="oona_aob_font">
              {translate("onboarding")["Just a few more steps to complete your Kahoona account registration."]}
              </div>

              <div className="oonna_success_buuton">
                <Col xl={24} md={24} lg={24} >

{
  idURldata.hasOwnProperty('idNumber') || idURldata.hasOwnProperty('idType') ? ( <Button className="oona_aob_font continue__button" 
  onClick={handleupdateprofile}
  >{translate("onboarding")["Continue"]}</Button>) : (<Button className="oona_aob_font continue__button" 
  disabled
  >{translate("onboarding")["Continue"]}</Button>)
}

                 
                </Col>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </Spin>
    </div>
   
    </>
  )
}

export default AOBsuucessScreenOCR
