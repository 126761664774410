import axios from "axios";
import apiConfig from "../config/api.config";
import { message, Alert } from "antd";
import rootIndex from "../store/root_index";
import { getOfflineData, savingDataOffline } from "./savingDataOffline";
import { toast } from "react-toastify";

const { store } = rootIndex;
const { baseURL, auth, secure, NODE_ENV } = apiConfig;

const ExecRequest = (config, options = { secure: true, multipart: false }) => {
  const _store = store.getState();
  console.log(_store, "csgyfd");
  console.log(process.env.REACT_APP_CI_REDIRECTIONURL_FOR_LINKEXPIRED,"redirection__part")
  console.log(_store.login.token, "sgdy=====>");
  const getingKeyClockidToken = localStorage.getItem("keyclock__data");
  const gettingUserInfoData=localStorage.getItem("userInfoToken")
  // const idTokenfromKeyClock = JSON.parse(getingKeyClockidToken);
  const idTokenfromKeyClock = getingKeyClockidToken;
  // console.log(idTokenfromKeyClock, "idTokenfromKeyClock");
  function promiseCallback(resolve) {
    let headers = {
      "Content-Type": options.multipart
        ? "application/pdf"
        : "application/json",
    };
    if (_store?.cipherlinkreducer?.formdata?.cipherkeyavilable === true) {
      headers = {
        ...headers,
        cipher: _store.cipherlinkreducer.formdata?.cipherkaye,
        // userInfo: _store.login.token,
      };
    } else {
      headers = {
        ...headers,
        // authorization: "Bearer " + _store.login.token,
        authorization: "Bearer " + idTokenfromKeyClock,
        userInfo: gettingUserInfoData,
      };
    }

    config.url = `${baseURL}${options.secure ? secure : ""}${config.url}`;

    axios({
      ...config,
      responseType: options?.multipart ? "blob" : undefined,
      headers: headers,
    })
      .then((res) => {
        const errCode = res.data.errCode;
        const data = res.data;
        if (data?.type === "application/pdf") {
          resolve(data);
        }

        if (errCode === -1) {
          resolve(data);
        } else if (errCode === 4) {
          if (typeof data.errMsg === "object") {
            message.warning(data?.errMsg?.Message || data?.errMsg?.msg);
          } else {
            message.warning(
              data?.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
            );
          }
          resolve(data);
          return;
        } else if (errCode === 25) {
          // alert("one")
          message.warning(
            data?.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
          );
          resolve(data);
        } else if (data?.errCode === 9) {
          resolve(data);
        } else if (errCode === 1) {
          if (data?.errMsg?.status === 402 || data?.errMsg?.status === 504) {
            let err = data;
            resolve(err);
            return;
          } else if (Array.isArray(data?.errMsg)) {
            const errormessageValue = data?.errMsg?.map((errormsg) => {
              return errormsg.value;
            });
            message.error(errormessageValue);
            resolve(data);
            return;
          } else if (typeof data?.errMsg === "object") {
            const errormessageValue = data?.errMsg?.map((errormsg) => {
              return errormsg.value;
            });
            message.error(errormessageValue);
            resolve(data);
            return;
          } else {
            message.warning(
              data?.errMsg ||
                data?.errMsg?.Message ||
                data?.errMsg?.msg ||
                data?.errMsg?.error
            );
            let err = data;
            resolve(err);
            return;
          }
        } else if (errCode === 121) {
          message.warning(
            data?.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
          );
          resolve(data);
        } else if (data?.errCode === 10201) {
          message.warning(
            data?.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
          );
          resolve(data);
        } else if (errCode === 2601) {
          resolve(data.errMsg);
          message.warning(
            data?.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
          );
        } else if (errCode === 529) {
          resolve(data);
          message.warning(
            data?.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
          );
        }
        else if(errCode === 422){
          toast.error( data?.errMsg?.message, {
            position: "top-center",
            hideProgressBar: true,
            autoClose: 1000,
          });

        } else {
          if (config.method !== "get") {
            message.error(
              data.errMsg || data?.errMsg?.Message || data?.errMsg?.msg
            );
          }
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response, "Geetha");
          if (error.response.status === 400 || error.response.status === 401) {
            // debugger
            try {
              toast.error(error.response?.data?.errMsg, {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 1000,
              });
              if (error.response.data.errCode === 81) {
              } else if (
                error.response.data.errCode === 25 &&
                error.response.status === 401
              ) {
                // alert("two")
                // alert("Session Timedout")
                // window.location.replace("/login");
              }
              // else if (error.response.data.errCode === 1 && error.response.status === 400) {
              //   toast.error(error?.response?.data?.errMsg?.message, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
              // }
              else if(error.response.data.errCode === 1){
                toast.error(error.response?.data?.errMsg?.message, {
                  position: "top-center",
                  hideProgressBar: true,
                  autoClose: 1000,
                });
        //         const keycloakBaseUrl = process.env.REACT_APP_KEYCLOCK_BASE_URL;
        //         const realm = process.env.REACT_APP_REALM_ID;
        //         const clientId = process.env.REACT_APP_CLIENT_ID;
              
        //         const logoutUrl = `${keycloakBaseUrl}/realms/${realm}/protocol/openid-connect/logout`;
        //         const formData = new URLSearchParams();
        //  formData.append('refresh_token', localStorage.getItem('keyclock__refreshToken'));
        //  formData.append('client_id', clientId);
      
        // // Make the POST request with application/x-www-form-urlencoded content type
        // axios.post(logoutUrl,formData, {
        //   headers: {
        //     'Accept':"*",
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Authorization': `Bearer ${localStorage.getItem('keyclock__accessToken')}`
        //   },
        //   withCredentials: true
        // }).then(response => {
        //   console.log('Logout successful:', response);
        //   window.location.href = process.env.REACT_APP_REDIRECINGURL;
        //   localStorage.clear()
        
        // })
        // .catch(error => {
        //   console.error('Logout failed:', error);
        // });



              }
              else if (error.response.data.errCode === 4) {
                toast.error(error.response?.data?.errMsg?.errmsg, {
                  position: "top-center",
                  hideProgressBar: true,
                  autoClose: 1000,
                });
              } else if (
                error?.response?.data?.errMsg === "given link is invalid" ||
                error?.response?.data?.errMsg?.message ===
                  "Given link is invalid"
              ) {
                window.location.replace("/linknotmatch");
              } else if (
                error.response?.data?.errMsg === "link is expired" ||
                error?.response?.data?.errMsg?.message === "Link has expired"
              ) {
                window.location.replace("/linknotvalid");
              } 
              else if(error.response?.data?.errMsg === "link is expired-ci" ||
                error?.response?.data?.errMsg?.message === "link is expired-ci"){
                  const expiredCIproductlink = process.env.REACT_APP_CI_REDIRECTIONURL_FOR_LINKEXPIRED
                  window.location.replace(expiredCIproductlink)
                }

              else if (error.response.data.errCode === 25) {
              
               
        //         const keycloakBaseUrl = process.env.REACT_APP_KEYCLOCK_BASE_URL;
        //         const realm = process.env.REACT_APP_REALM_ID;
        //         const clientId = process.env.REACT_APP_CLIENT_ID;
              
        //         const logoutUrl = `${keycloakBaseUrl}/realms/${realm}/protocol/openid-connect/logout`;
        //         const formData = new URLSearchParams();
        //  formData.append('refresh_token', localStorage.getItem('keyclock__refreshToken'));
        //  formData.append('client_id', clientId);
      
        // // Make the POST request with application/x-www-form-urlencoded content type
        // axios.post(logoutUrl,formData, {
        //   headers: {
        //     'Accept':"*",
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Authorization': `Bearer ${localStorage.getItem('keyclock__accessToken')}`
        //   },
        //   withCredentials: true
        // }).then(response => {
        //   console.log('Logout successful:', response);
        //   window.location.href = process.env.REACT_APP_REDIRECINGURL;
        //   localStorage.clear()
        
        // })
        // .catch(error => {
        //   console.error('Logout failed:', error);
        // });
     
                // alert("three")
                window.location.replace("/app/login");
              }
            } catch (error) {}
          } else if (
            error?.response?.status === 500 ||
            error?.response?.status === 504
          ) {
            try {
              if (error?.response?.data?.errCode === 4) {
                resolve(error?.response?.data?.errMsg?.message);
              }
            } catch (error) {}
          } else if (error?.response?.status === 406) {
            try {
              if (error?.response?.data?.errCode === 1) {
                if (error?.response?.data?.errMsg) {
                  toast.error(error.response?.data?.errMsg, {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 1000,
                  });
                }

                resolve(error?.response?.data?.errMsg);
              }
            } catch (error) {}
          } else if (error?.response?.status === 409) {
            try {
              if (error?.response?.data?.errCode === 1) {
                if (error?.response?.data?.errMsg) {
                  toast.error(error.response?.data?.errMsg, {
                    position: "top-center",
                    hideProgressBar: true,
                    autoClose: 1000,
                  });
                } else if (error?.response?.data?.errMsg?.message) {
                  message.error(error?.response?.data?.errMsg?.message);
                } else {
                  message.error(error?.response?.data?.errMsg?.message);
                }
                resolve(error?.response?.data?.errMsg);
              }
            } catch (error) {}
          } else if (error?.response?.status === 422) {
            if (error?.response?.data?.errCode === 4) {
              toast.error(error.response?.data?.errMsg, {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 1000,
              });
            }
          }
        } else if (error.request) {
          message.error("Request failed");
        } else {
          message.error(`${error.name}: ${error.message}`);
        }
        resolve(null);
      });
  }
  return new Promise(promiseCallback);
};

export default {
  get: async (endPoint, options = { secure: true, multipart: false }) => {
    if (!navigator.onLine) {
      // if(true){
      const result = await getOfflineData(endPoint, "get", baseURL);
      return !result ? [] : result;
    }

    options = options.multipart
      ? options
      : {
          ...options,
          multipart: false,
        };
    let result = await ExecRequest(
      {
        method: "get",
        url: endPoint,
      },
      options
    );

    savingDataOffline(result, endPoint, "get", baseURL);

    return !result ? [] : result;
  },
  post: async (
    endPoint,
    dataBody,
    options = { secure: true, multipart: false }
  ) => {
    options = options.multipart
      ? options
      : {
          ...options,
          multipart: false,
        };
    let result = await ExecRequest(
      {
        method: "post",
        url: endPoint,
        data: dataBody,
      },
      options
    );

    return !result ? [] : result;
  },
  put: async (
    endPoint,
    dataBody,
    options = { secure: true, multipart: false }
  ) => {
    options = options.multipart
      ? options
      : {
          ...options,
          multipart: false,
        };
    let result = await ExecRequest(
      {
        method: "put",
        url: endPoint,
        data: dataBody,
      },
      options
    );

    return !result ? [] : result;
  },
  patch: async (
    endPoint,
    dataBody,
    options = { secure: true, multipart: false }
  ) => {
    options = options.multipart
      ? options
      : {
          ...options,
          multipart: false,
        };
    let result = await ExecRequest(
      {
        method: "patch",
        url: endPoint,
        data: dataBody,
      },
      options
    );

    return !result ? [] : result;
  },
};
