import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form,  Input, Button, Spin } from "antd"
import SuccessLogo from "./../oonaImages/GRFX Success State.png"
import { useHistory } from "react-router-dom"
import InputImage from "./../oonaImages/Frame 1000004254.png"
import {  toast } from 'react-toastify';
import { useLanguage } from "./../config/LanguageContext"
import axiosRequest from './../axios-request/request.methods'
import CustomerHeader from './../CustomerHeader/CustomerHeader'
import * as action from './../store/actions/index'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import rootIndex from "./../store/root_index";

const { store } = rootIndex;

function PaymentSuccessScreen() {

    const { translate } = useLanguage()
    const location = useLocation();
    const _store = store.getState();
    const searchParams = new URLSearchParams(location.search);
    const checkingisAgent = searchParams.get('isAgent');
    const checkingCipher = searchParams.get('cipher');
console.log(searchParams,"searchParams")
console.log(checkingCipher,"checkingCipher")
console.log(checkingisAgent,"checkingisAgent")
const { Quoteid } = useParams(); 
    console.log(Quoteid,"id==>")
    const history = useHistory()
    const [travellink, setTravelLink] = useState('')
    console.log(travellink,"travellink")
    const [loading, setLoading] = useState(false)
    const [policy, setPolicy] = useState('')
    const [productName, setProductName] = useState('')
    console.log(productName,"productName")
    const [form] = Form.useForm();
    const dispatch=useDispatch()
    
    useEffect(() => {
        form.setFieldsValue({
            copyLink: travellink
        })

        setTravelLink(travellink)
    }, [travellink])


    useEffect(()=>{
        if(checkingisAgent === "false"){
            let Data ={
                cipherkaye:checkingCipher,
                cipherkeyavilable:true
            }
            dispatch(action.fetch_cipher_data(Data))   
        }
    },[checkingisAgent])

    useEffect(() => {
        getLink()
        getQuotes()
    }, [])

    const getQuotes = () => {

        axiosRequest
            .get(
                `user/get-quotes?id=${Quoteid}`,

            )
            .then((data) => {
                //console.log("Final response: of get quote lead", data.data.errMsg.data);
                if (data?.errCode === -1) {
                    console.log(data.errMsg.data[0].proposalNo,"sbjdgyu")
                    if (data?.errMsg?.data[0]?.proposalNo !== undefined && data?.errMsg?.data[0]?.proposalNo !== '') {
                        //console.log(data?.errMsg,"chcgy")
                        console.log(data?.errMsg,"bsjgcj")
                        setPolicy(data?.errMsg?.data[0]?.policyNo)
                    }
                    if (data?.errMsg?.data[0]?.product_Id === "64aff2f3831667919245f86c") {
                        setProductName('Travel Insurance')
                    }

                    if (data?.errMsg?.data[0]?.product_Id === "64c0fd38f6d4bddffcac8f71") {
                        setProductName('Motor Insurance 2W')
                    }

                    
                    if (data?.errMsg?.data[0]?.product_Id === "64c0fd4af6d4bddffcac8f72") {
                        setProductName('Motor Insurance 4W')
                    }


                    if (data?.errMsg?.data[0]?.product_Id === "64c34807507810de0263ff63") {
                        setProductName('Home Insurance')
                    }



                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }


    const getLink = () => {
      
        axiosRequest
            .get(
                `user/generate-link?type=lead&quoteId=${Quoteid}&isEPdfLink=true&pdfType=policy`,
               
            )
            .then((data) => {
                if (data.errCode === -1) {
                    //console.log(data.data.errMsg.link, 'Generate link Response----->');
                    setTravelLink(data.errMsg.link)
                }
            }).catch((error) => {
                console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

            });
    }

   

    const policyPdf = () => {
        setLoading(true)

           let  options = { secure: true, multipart: true }
        axiosRequest
            .get(
                `user/e-policy?quoteId=${Quoteid}&logkey=policyDownloadNotification`,options)
            .then((response) => { 
                console.log(response,"bcshjfgy")
                setLoading(false)
                    const blob = response ;
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    console.log(fileURL,"fileURLfileURL==>")
                    // Setting various property values
                    const alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = `PS_${productName}_${policy}.pdf`;
                    alink.click();

                    // Clean up the object URL after the download is triggered
                    window.URL.revokeObjectURL(fileURL);
                
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    }


    const handleCopyLInk = () => {
        navigator.clipboard.writeText(travellink)
        toast.success(`${translate("home")["link copied"]}`, { position: "top-center", hideProgressBar: true, autoClose: 1000 })
    }
    return (
        <>
                <CustomerHeader/>
            <div>
                <Spin tip="Loading" spinning={loading} >
                    <Row className='success-content'>
                        <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                            <Image preview={false} src={SuccessLogo} />
                        </Col>
                        <Col xl={12} md={12} lg={12} xs={24} sm={24}>
                            <h5 className='header-card-content'>{translate('travel')["Success!"]}!</h5>
                            <div><b>{translate('home')["E-policy has been sent to you via email"]}</b></div>
                            <div style={{ fontSize: 12, color: "lightgray" }}>{translate('travel')["Reference no"]} : {policy}</div>
                            <br />
                            {
                                checkingisAgent == "true" && ( <Form form={form}>
                                <div><b>{translate('home')["SendPaymentSuccessLink"]}</b></div>
                                <Form.Item
                                    name="copyLink"
                                >
                                    <Input value={travellink} suffix={<Image className="input-img" src={InputImage} preview={false} onClick={() => handleCopyLInk()} />}  className='link-input' />
                                </Form.Item>
                            </Form>)
                            }

                           
                            <Row>
                                    <Col md={12} xl={12} sm={12} xs={12} lg={12}>
                                        <Button onClick={() => history.push('/oonahome')} className='no-button'>{translate("motor")["Back to Dashboard"]}</Button>
                                    </Col>
                                    <Col md={12} xl={12} sm={12} xs={12} lg={12}>
                                        <Button className='next-button-travel' onClick={() => policyPdf()}>{translate('home')["Print Policy"]}</Button>
                                    </Col>
                                    </Row>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    )
}

export default PaymentSuccessScreen