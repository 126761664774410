import React, { useEffect } from 'react'
import rootIndex from "../store/root_index";
import { useHistory } from "react-router-dom"
import { Base_URL } from '../config/BaseUrl';
import { useDispatch } from "react-redux";
import axiosRequest from '../axios-request/request.methods'
import * as action from '../store/actions/index'
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';


const { store } = rootIndex;
function MotorDynamicRoute(props) {
    const _store = store.getState();
    const history = useHistory();
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');
    console.log(langParam,"langParam")
    useEffect(() => {
        console.log("Datat Check Props", props)
        console.log(props.match.params.id, 'params-----> Check ID==');
        // return

        if (props.match.params.id.length == 6) {
            let Data = {
                cipherkaye: props.match.params.id,
                cipherkeyavilable: true
            }

            dispatch(action.fetch_cipher_data(Data))
            let options = { secure: false, multipart: false }
            axiosRequest
                .get(
                    `auth/decrypt-link-motor`,
                    options
                )
                .then((data) => {
                    if (data.errCode === -1) {
                       
                        if (data.errMsg.data.hasOwnProperty("stage") === false) {
                            dispatch(action.set_global_language(langParam));
                            Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                            let Data = {
                                quoteId: "",
                                isOld: false
                            }
    
                            dispatch(action.set_quote_id(Data))
                            history.push("/motorscreen")


                        } else {
                            if (data?.errMsg?.data?.stage === 'Plan Selected') {
                                dispatch(action.set_global_language(langParam));
                                Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                                if (data.errMsg.data.productId == "64c0fd4af6d4bddffcac8f72") {
                                    dispatch(action.set_quote_id({
                                        quoteId: data.errMsg.data.quoteId,
                                        isOld: false,
                                      }));
                        
                                    history.push({
                                        pathname: "/motoradditionalbenefits",
                                        // state: {
                                        //     cipher: props.match.params.id,
                                        //     quoteId: data.errMsg.data.quoteId,
                                        //     getQuote: 'yes'
                                        // }
                                    })
                                } else {
                                    dispatch(action.set_quote_id({
                                        quoteId: data.errMsg.data.quoteId,
                                        isOld: false,
                                      }));
                                    history.push({
                                        pathname: "/motorordersummary",
                                        state: {
                                            // cipher: props.match.params.id,
                                            // quoteId: data.errMsg.data.quoteId,
                                            // getQuote: 'yes',
                                            // quote: true

                                        }
                                    })
                                }
                            }
                            else if (data.errMsg.data.stage == "Lead Created") {
                                dispatch(action.set_global_language(langParam));
                                Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                                console.log();
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                  }));
                                history.push({
                                    pathname: "/motorchooseproduct",
                                    // state: {
                                    //     cipher: props.match.params.id,
                                    //     data: props.location.state.data,
                                    //     quoteId: data.errMsg.data.quoteId
                                    // },
                                });
                            } else if (data.errMsg.data.stage == 'Additional Benefit Selected') {
                                dispatch(action.set_global_language(langParam));
                                Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                  }));
                                history.push({
                                    pathname: "/motorordersummary",
                                    // state: {
                                    //     cipher: props.match.params.id,
                                    //     quoteId: data.errMsg.data.quoteId,
                                    //     getQuote: 'yes',
                                    //     // quote: true

                                    // }
                                })
                            }
                            else if (data.errMsg.data.stage == 'PromoCode Added') {
                                dispatch(action.set_global_language(langParam));
                                Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                  }));
                                history.push({
                                    pathname: "/oonasuccess",
                                    // state: {
                                    //     cipher: props.match.params.id,
                                    //     data: props.location.state.data,
                                    //     quoteId: data.errMsg.data.quoteId
                                    // }
                                })

                            }
                            else if (data.errMsg.data.stage == 'Customer Information Updated') {
                                if (data.errMsg.data.productId == "64c0fd4af6d4bddffcac8f72"){
                                    dispatch(action.set_quote_id({
                                        quoteId: data.errMsg.data.quoteId,
                                        isOld: false,
                                      }));
                               
                                    
                                    history.push({
                                        pathname: "/oonariskinspectionmotor",
                                        // state: {
                                        //     cipher: props.match.params.id,
                                        //     quoteId: data.errMsg.data.quoteId,
                                        //     quoteNo: data.errMsg.data.quote_Id
                                        // }
                                    })
                                }
                                else {
                                    dispatch(action.set_quote_id({
                                        quoteId: data.errMsg.data.quoteId,
                                        isOld: false,
                                        resumeType:'bike'
                                      }));
                                    history.push({
                                        pathname: "/oonariskinspectionbike",
                                        // state: {
                                        //     cipher: props.match.params.id,
                                        //     quoteId: data.errMsg.data.quoteId,
                                        //     quoteNo: data.errMsg.data.quote_Id,
                                        //     data: 'bike'

                                        // }
                                    })

                                }
                            } else if (data.errMsg.data.stage == 'Qc Checked') {
                                if (data.errMsg.data.productId == "64c0fd4af6d4bddffcac8f72"){
                                    dispatch(action.set_quote_id({
                                        quoteId: data.errMsg.data.quoteId,
                                        isOld: false,
                                      }));
                               
                                    history.push({
                                        pathname: "/oonariskinspectionmotor",
                                        // state: {
                                        //     cipher: props.match.params.id,
                                        //     quoteId: data.errMsg.data.quoteId
                                        // }
                                    })
                                }
                                else {
                                    dispatch(action.set_quote_id({
                                        quoteId: data.errMsg.data.quoteId,
                                        isOld: false,
                                        resumeType:'bike'
                                      }));
                                    history.push({
                                        pathname: "/oonariskinspectionbike",
                                        // state: {
                                        //     cipher: props.match.params.id,
                                        //     quoteId: data.errMsg.data.quoteId,
                                        //     data: 'bike'
                                        // }
                                    })

                                }

                            }
                            else if (data.errMsg.data.stage == 'Quick Quote Created') {
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                    resumeType:'bike'
                                  }));
                                history.push({
                                    pathname: "/motorordersummary",
                                    // state: {
                                    //     cipher: props.match.params.id,
                                    //     quoteId: data.errMsg.data.quoteId,
                                    //     quote: true
                                    // }
                                })

                            }
                            else if (data.errMsg.data.stage == 'Q C Inspection Done') {
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                  }));
                                history.push({
                                    pathname: "/oonaconfirmationMotor",
                                    state: {
                                        cipher: props.match.params.id,
                                        quoteId: data.errMsg.data.quoteId
                                    }
                                })
                            }

                            else if (data.errMsg.data.stage == 'Full Quote Created') {
                                dispatch(action.set_global_language(langParam));
                                Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                    resumeType:'motor'
                                  }));
                                history.push({
                                    pathname: "/oonapayment",
                                    // state: {
                                    //     cipher: props.match.params.id,
                                    //     quoteId: data.errMsg.data.quoteId,
                                    //     data: 'motor'
                                    // }
                                })
                            } else if (data.errMsg.data.stage == 'Policy Generated') {
                                dispatch(action.set_global_language(langParam));
                                Cookies.set('LANG', langParam === 'en' ? 'en' : 'id', { domain: process.env.REACT_APP_COOKIE_URL });
                                dispatch(action.set_quote_id({
                                    quoteId: data.errMsg.data.quoteId,
                                    isOld: false,
                                    resumeType:'motor'
                                  }));

                                history.push({
                                    pathname: "/oonapayment",
                                    // state: {
                                    //     cipher: props.match.params.id,
                                    //     quoteId: data.errMsg.data.quoteId,
                                    //     data: 'motor'
                                    // }
                                })
                            }
                        }
                    }
                  
                }).catch((error) => {
                    if(error?.response?.data?.errMsg === "given link is invalid"){
                        history.push("/linknotmatch")
                    }else if(error?.response?.data?.errMsg === "link is expired"){
                        history.push("/linknotvaild")
                    }
                    console.log("error:2---forgotpass-----> ", typeof (error), error.Error, error.message);

                });
        } else {
            history.push("/login")
            let Data = { cipherkeyavilable: false }

            dispatch(action.fetch_cipher_data(Data))
        }

    }, [])
    return (
        <div></div>
    )
}

export default MotorDynamicRoute